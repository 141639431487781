<template>
  <div class="brand w-full pb-10">
    <div class="collection-banner-container" :style="{ 'background-color': banner.data.background_color || 'rgb(121, 95, 70)', }" >
      <Banner title="Coming Soon" :toggle="false" > <template #logo>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" class="w-24 h-24" >
            <path
              fill="#795f46"
              fill-rule="evenodd"
              d="M11.515 3.333H5.151c-1.178 0-1.818.64-1.818 1.819v6.363c0 1.127.585 1.761 1.667 1.815V8a3 3 0 013-3h5.33c-.054-1.082-.688-1.667-1.815-1.667z"
              clip-rule="evenodd"
              opacity=".3"
            />
            <path
              fill="#795f46"
              fill-rule="evenodd"
              d="M8.485 6.667h6.364c1.178 0 1.818.64 1.818 1.818v6.364c0 1.178-.64 1.818-1.819 1.818H8.485c-1.179 0-1.818-.64-1.818-1.819V8.485c0-1.179.64-1.818 1.818-1.818z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span>
                <a href="#">Coming Soon</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <div class="-mb-8">
            <!-- <Sorting  @onSort="onSortChange"/> -->
          </div>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>
    <div class="py-10 max-container">
      <div class="flex items-center space-x-4 pb-5">
        <FilterBadge />
      </div>
      <ListLoaderWrapper :isLoading="products.loading" />
      <ItemsList :items="products" :filter="filters" :loading="products.loading"/>
      <ListLoaderWrapper class="mt-5" :isLoading="products.onScrollLoading" />
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import Banner from "../common/Banner.vue";
import ItemsList from "@/views/pages/common/ItemsList.vue";
import { mapGetters } from "vuex";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import Common from "../../../modules/Common";

export default {
  name: "Home",
  components: {
    Banner,
    ItemsList,
    ListLoaderWrapper,
  },
  watch: {
    filters: {
      handler: "filterCategory",
      deep: true,
    }
  },
  computed:{
    ...mapGetters({filters: "getSelected"}),
  },
  methods: {
    filterCategory() {
      this.onFilterChanged();
    }
  },
  setup() {
   
    const banner = reactive({
      loading: false,
      data: [],
      error: "",
    });

    const name = "comingsoon"
    const LoginURL ="/comingsoon";
    const LogoutURL = "/comingsoonProducts";

    const { 
      products,
      onFilterChanged
    } = Common({name},{LoginURL,LogoutURL});
    onFilterChanged();

    return {
      banner,
      products,
      onFilterChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}
</style>
